// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../images/dublin.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".home {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100%;\n  background-position: bottom left;\n  background-repeat: no-repeat;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n}\n.home .panels {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  position: relative;\n  top: 110px;\n}\n.home .panels.fhf {\n  flex-direction: row;\n  justify-content: center;\n}\n.home .panels.fhf .box-content {\n  margin-left: 25px;\n  margin-right: 0;\n}\n.home .message {\n  -webkit-animation: 2s ease 0s normal forwards 1 fadein;\n  animation: 2s ease 0s normal forwards 1 fadein;\n  max-width: 900px;\n}\n@keyframes fadein {\n  0% {\n    opacity: 0;\n  }\n  66% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n@-webkit-keyframes fadein {\n  0% {\n    opacity: 0;\n  }\n  66% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n.home .message .alert {\n  padding: 0;\n  margin: 0;\n}\n.home .message .alert p {\n  margin: 0;\n}\n.home .navbar-light {\n  position: absolute;\n  right: 15px;\n  top: 10px;\n}\n.home .gclef {\n  max-width: 25%;\n  display: inline-block;\n  float: left;\n  padding-right: 25px;\n}\n", ""]);
// Exports
module.exports = exports;
